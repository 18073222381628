import React from 'react';
import { ExpandCollapse } from '@tds/core-expand-collapse';
import Notification from '@tds/core-notification';
import Text from '@tds/core-text'
import Box from '@tds/core-box';
import Heading from '@tds/core-heading';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { NormalText } from '../../styles/elements';
import { dataKeys, fieldNames } from '../../config/constants';

const Summary = ({ stepData, noOfEmployess }) => {
  const [t] = useTranslation();
  const { ADMIN, THATSME } = dataKeys;
  const {
    legalBusinessName,
    firstName,
    lastName,
    email,
    mobileNumber,
    title,
    plan,
    term,
    offer,
    eligibleEmployees,
    province,
    preferredLanguage,
    additionalEmployee,
    noOfEmployees,
    totalPerMonth
  } = fieldNames;

  const getLabelText = (fieldName) => {
    switch (fieldName) {
      case firstName:
        return t('FIRST_NAME');
      case lastName:
        return t('LAST_NAME');
      case legalBusinessName:
        return t('LEGAL_BUSINESS_NAME');
      case email:
        return t('EMAIL');
      case mobileNumber:
        return t('MOBILE_NUMBER');
      case title:
        return t('TITLE');
      case plan:
        return t('PLAN');
      case term:
        return t('TERM');
      case offer:
        return t('OFFER');
      case eligibleEmployees:
        return t('ELIGIBLE_EMPLOYEES');
      case province:
        return t('PROVINCE');
      case preferredLanguage:
        return t('PREFERRED_LANGUAGE');
      case additionalEmployee:
        return t('ADDITIONAL_EMPLOYEE_TITLE');
      case noOfEmployees:
         return t('NO_OF_EMPLOYEE');
      case totalPerMonth:
          return t('PRODUCT_INFO_TOTAL_PER_MONTH');
      default:
        return fieldName;
    }
  };
  const productDetails = {
    offer: t('PRODUCT_OFFER'),
    eligibleEmployees: noOfEmployess,
    additionalEmployee: stepData.additionalEmployee,
    noOfEmployees: stepData.noOfEmployees,
    totalPerMonth: stepData.totalPerMonth ? `$${stepData.totalPerMonth}` : stepData.totalPerMonth,
  };

  const aboutYouRemovedKeys = [ADMIN,additionalEmployee,noOfEmployees, totalPerMonth]
  
  return (
    <Box horizontal={3}>
      <ExpandCollapse
        tag="h2"
        open={['aboutYou', 'assignAnAdmin', 'productDetails']}
      >
        <ExpandCollapse.Panel id="aboutYou" header={t('SUMMARY_SECTION_ONE')}>
          <Box between={3}>
            {Object.keys(stepData).map(
              (key) =>
                (!(aboutYouRemovedKeys.includes(key))) && (
                  <Box between={2} key={`mainKey${key}`}>
                    <Heading level="h4">
                      {getLabelText(key)} :{' '}
                      <NormalText>{stepData[key]}</NormalText>
                    </Heading>
                  </Box>
                )
            )}
          </Box>
        </ExpandCollapse.Panel>

        <ExpandCollapse.Panel id="assignAnAdmin" header={t('SUMMARY_SECTION_TWO')}>
          <Box between={3} below={2}>
          <Notification copy="en">
              <Text bold>{t('NOTE')}:</Text> {t('STEP_2_DESCRIPTION')}
          </Notification>
          </Box>
          <Box between={3}>
            {stepData.adminData &&
              Object.keys(stepData.adminData).map(
                (key) =>
                  key !== THATSME && (
                    <Box between={2} key={`adminKey${key}`}>
                      <Heading level="h4">
                        {getLabelText(key)} :{' '}
                        <NormalText>{stepData.adminData[key]}</NormalText>
                      </Heading>
                    </Box>
                  )
              )}
          </Box>
        </ExpandCollapse.Panel>
        <ExpandCollapse.Panel id="productDetails" header={t('SUMMARY_SECTION_THREE')}>
          <Box between={3} below={2}>
            <Heading level='h3'>{t('ORDER_DETAILS')}</Heading>
          </Box>
          <Box between={3}>
            {productDetails &&
              Object.keys(productDetails).map((key) => (
                <Box between={2} key={`productDetail${key}`}>
                  <Heading level="h4">
                    {getLabelText(key)} :{' '}
                    <NormalText>{productDetails[key]}</NormalText>
                  </Heading>
                </Box>
              ))}
          </Box>
        </ExpandCollapse.Panel>
      </ExpandCollapse>
    </Box>
  );
};

Summary.propTypes = {
  stepData: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default Summary;
