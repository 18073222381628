import React from 'react';
import './styles.css';
import {
  TermsAndConditions,
} from '../../styles/elements';

const Terms = () => {
  return (
    <TermsAndConditions>
      <div className='agreement' id="termsAndConditions">
        <p>The order details summarize the charge(s) for the services you have ordered from TELUS when you accept this agreement.The Order Form, together with the below terms and conditions, form the agreement between TELUS and the Client.</p>
        <p>These terms and conditions are between TELUS Communications (“TELUS”); and the Client, who is the legal entity as set out on the Order Form.</p>
        <p>TELUS agrees to provide you with the Services (as described in the Service Description Section below). This document sets out the terms that you and TELUS agree to in relation to these Services.</p>
        <p>By clicking “I AGREE” or similar electronic acceptance, you agree with these General Terms and Conditions forming a legally binding agreement between you and TELUS.  If you do not accept and agree to be bound by these General Terms and Conditions, please do not use the TELUS Health MyCare Business Solution. The agreement includes other documents described in or attached to this agreement when it is signed, but does not include any other statements or documents. This agreement replaces any earlier agreements between TELUS and you relating to the services in this agreement.</p>
      
        <ol className='mainList'>
          <li>Services
        <ol className='outerList'>
              <li>The Client acknowledges that (a) the Services (as described in the Service Description Section below) to the Eligible Employees are provided by the Health Service Provider via the TELUS Health MyCare Business Solutionand such use is governed by the End User Terms; and (b) use of the TELUS Health MyCare Business Solution by Eligible Employees and Dependents and the supply of any services or products through the TELUS Health MyCare Business Solution requires Eligible Employees and Dependents to enter into End User Terms with the Health Service Provider</li>
              <li>The Client acknowledges and agrees that the TELUS Health MyCare Business Solution may be modified from time to time in order to comply with any applicable law or safety requirements, or otherwise in such manner in which the Health Service Provider or the Health Service Provider’s affiliates considers (in its sole discretion) are reasonable or desirable, provided that the Client is not obliged to pay for Services not actually provided to an Eligible Employee or products not actually provided to an Eligible Employee.</li>
              <li>The Client agrees to:
            <ol className='innerList'>
                  <li>provide TELUS with a list of Eligible Employees and Dependents, (the “Eligibility List”) within ten (10) days of the Start Date of the Agreement. Client is further obligated to maintain the accuracy of the Eligibility List and to advise TELUS of any and all changes to this Eligibility List in a timely manner, but no later than 30 days following any addition or deletion of employees and dependents to the Eligibility List.</li>
                  <li>co-operate with TELUS in all matters where reasonably requested in connection with the validation of Eligible Employees and ensuring that only appropriate persons are identified as Eligible Employees and Dependents, including keeping the Eligibility List up to date, notifying TELUS promptly upon any Eligible Employee or Dependent ceasing to be entitled to use the TELUS Health MyCare Business Solution and responding promptly to any requests by TELUS in connection with validating users; and</li>
                  <li>cooperate with TELUS on adhoc list audits;</li>
                  <li>use all reasonable endeavours to promote the TELUS Health MyCare Business Solution to its employees and staff and to encourage them to use the TELUS Health MyCare Business Solution; and</li>
                  <li>promptly inform TELUS of any complaints, adverse events or feedback the Client receives from Eligible Employees or becomes aware of regarding the Services or TELUS Health MyCare Business Solutionr or the TELUS Heath MyCare App.</li>
                </ol>
              </li>
            </ol>
          </li>
          <li>Charges and payment
        <ol className='outerList'>
              <li>The Client shall pay to TELUS the Total Fees for the Premium Services in accordance with the Payment Terms as set forth in the Order Form. The Client will receive their monthly bill either by mail or online. All amounts owing to TELUS are due on the day you receive your bill if you get a TELUS paper bill or the day your bill is posted online if you get an electronic bill (e.Bill). You can register for e.Bill notifications, whereby you will receive an email or text message informing you that your bill has been posted online. Your bill tells you how much to pay and when that payment must reach TELUS to avoid late payment charges.</li>
              <li>TELUS may adjust the Price per Eligible Employee by giving the client notice at least one month in advance.</li>
              <li>If TELUS determines that an Eligible Employee on the Eligibility List exceeds the value of the Order Form without notice to TELUS, TELUS will issue an invoice accordingly.</li>
            <li>The Client may add new Eligible Employees or Dependents at any time during the Term by notifying TELUS within 30 days. The new Eligible Employees will be captured on the subsequent monthly invoice, if applicable.</li>
              <li>If TELUS determines that an Eligible Employee on the Eligibility List exceeds the value of the Order Form without notice to TELUS, TELUS will issue an invoice accordingly.</li>
              <li>If TELUS determines that an Eligible Employee or Dependent on the Eligibility List no longer meets applicable Promotional Eligibility Requirements, TELUS will issue an invoice accordingly</li>
              <li>All amounts payable by the Client under this Agreement are exclusive of all taxes, customs, duties or tariffs or other similar taxes. Past due amounts shall bear interest at the rate of 2% per month, 26.8% per annum.</li>
              <li>The Client shall pay all amounts due under the Agreement in full without any set-off, counterclaim, deduction or withholding (except for any deduction or withholding required by law).</li>
            </ol>
          </li>
          <li>Intellectual property rights
        <ol className='outerList'>
              <li>As between Client on the one hand and TELUS and its partners (including Babylon) on the other hand, TELUS and/or its partners (including Babylon) shall own and retain all intellectual property rights, whether currently existing or hereafter developed or acquired, and all applications, disclosures and registrations with respect thereto) in connection with and to the Services, the TELUS Health MyCare App, TELUS Health MyCare Business Solution any documentation, and all legally protectable elements, derivative works, modifications and enhancements thereto, whether or not developed in conjunction with Client, and whether or not developed by TELUS and its partners (including Babylon), Client or any contractor, subcontractor or agent for TELUS and its partners (including Babylon) or Client. Nothing in this Agreement shall cause the transfer of ownership of, or grant a licence, under any intellectual property rights of TELUS, Babylon or Babylon’s affiliates to the Client.</li>
            </ol>
          </li>
          <li>Confidentiality
        <ol className='outerList'>
              <li>Each party undertakes that it shall not disclose to any person any Confidential Information, except as permitted by clause 4.2.</li>
              <li>Each party may disclose the other party's Confidential Information:
            <ol className='innerList'>
                  <li>to its employees, officers, representatives, subcontractors or advisers who need to know such information for the purposes of carrying out the party's obligations under this Agreement. Each party shall ensure that its employees, officers, representatives, subcontractors or advisers to whom it discloses the other party's Confidential Information comply with this clause 4; or</li>
                  <li>as may be required by law, a court of competent jurisdiction or any governmental or regulatory authority, provided that, unless prohibited by law or regulation, it notifies the other party in advance prior to making any such disclosure.</li>
                </ol>
              </li>
              <li>Neither party shall use the other party's Confidential Information for any purpose other than to exercise its rights and perform its obligations under this Agreement. For the avoidance of doubt, TELUS shall, to the extent necessary in order for TELUS to exercise its rights and perform its obligations under this Agreement, disclose the Client’s Confidential Information to Babylon.</li>
            </ol>
          </li>
          <li>Limitation of liability
        <ol className='outerList'>
              <li>Disclaimer of Warranty. Except as expressly set forth herein and to the extent permitted by law, the Services, the TELUS Health MyCare Business Solution and any data (including third party data), information or services accessed or provided in connection with this Agreement are provided “as is” and “as available”. TELUS, Babylon and Babylon’s affiliates disclaim all statutory representations, conditions and warranties, express or implied, including but not limited to any implied warranties of merchantability, accuracy, integrity, freedom, non-infringement or fitness for a particular purpose, or arising from course of performance or course of dealing, or that services or the application will provide error-free or uninterrupted functionalities.</li>
              <li>Limitation of Liability. In no event will TELUS, Babylon or its affiliates be liable to the Client or to any other entity arising out of or related to this Agreement for any (a) special, indirect, incidental or consequential damages, (b) loss of data or profit or business interruption, arising out of or related to this Agreement, under any theory, whether or not foreseeable, whether or not advised of the possibility of such damage, (c) in an aggregate amount exceeding the Fees paid by Client to TELUS in the six (six) months before the event giving rise to the claim. The Parties understand and accept this allocation of liability and risk.
          </li>
            </ol>
          </li>
          <li>Termination
        <ol className='outerList'>
              <li>Unless terminated by either party in accordance with clause 6.2 or by TELUS in accordance with clause 6.3, this Agreement shall come into force on the day in which this agreement is signed by and shall automatically renew for successive periods of one month unless terminated by either party giving no less than 30 days notice to the other party upon which this Agreement shall terminate on the next monthly anniversary of the Start Date which occurs after such minimum notice period.</li>
              <li>Without affecting any other right or remedy available to it, either party may terminate this Agreement with immediate effect by giving written notice to the other party if:
            <ol className='innerList'>
                  <li>the other party commits a material breach of any term of this Agreement and (if such a breach is remediable) fails to remedy that breach within 30 (thirty) days of that party being notified in writing to do so;</li>
                  <li>the other party takes any step or action in connection with its entering administration, provisional liquidation or any composition or arrangement with its creditors (other than in relation to a solvent restructuring), being wound up (whether voluntarily or by order of the court, unless for the purpose of a solvent restructuring), having a receiver appointed to any of its assets or ceasing to carry on business or, if the step or action is taken in another jurisdiction, in connection with any analogous procedure in the relevant jurisdiction; or</li>
                  <li>the other party suspends, or threatens to suspend, or ceases or threatens to cease to carry on all or a substantial part of its business.</li>
                </ol>
              </li>
              <li>Without affecting any other right or remedy available to it, TELUS may terminate or suspend this Agreement with immediate effect by giving 60 days prior written notice to the Client.</li>
            </ol>
          </li>
          <li>Consequences of termination
        <ol className='outerList'>
              <li>On termination of this Agreement for any reason:
            <ol className='innerList'>
                  <li>he Client shall immediately pay to TELUS all of TELUS's outstanding unpaid invoices and interest and, in respect of the Services supplied but for which no invoice has been submitted, TELUS shall submit an invoice, which shall be payable by the Client immediately on receipt.</li>
                  <li>the Client shall destroy all marketing materials and any other materials which TELUS has provided to the Client during the course of this Agreement;</li>
                  <li>without prejudice to any other rights provided in this Agreement, each party shall return to the other party all confidential information of the other party in its possession or control, or at the disclosing party’s option, destroy such confidential information, including any copies or reproductions thereof, with such destruction confirmed in writing. This provision does not apply to electronic copies made as part of the receiving party’s standard computer back-up practice, subject to such electronic copies continuing to be subject to clause 4;</li>
                  <li>TELUS shall immediately cease to allow the Eligible Employees and Dependents to use the TELUS Health MyCare Business Solution</li>
                  <li>remedies, obligations and liabilities of the parties as at expiry or termination shall be unaffected, including the right to claim damages in respect of any breach of this Agreement which existed at or before the date of termination or expiry; and</li>
                  <li>clauses 4,5 6, 8 and 10 to 19 shall continue in full force and effect.</li>
                </ol>
              </li>
            </ol>
          </li>
          <li>Privacy
        <ol className='outerList'>
              <li>TELUS and Client are each responsible for complying with their respective obligations under Privacy Laws. With respect to Personal Information transferred or otherwise made available to TELUS by Client or by any third party on Client's behalf, Client represents, warrants, covenants and agrees that the Client has the authority and/or has obtained all necessary consents from the Eligible Employees required under applicable Privacy Laws to enable the Personal Information to be transferred, disclosed, processed, copied, altered, stored, deleted, or otherwise used by TELUS for the provision of the Services and intended purposes identified in this Agreement. TELUS maintains reasonable physical, technical and organisational measures to protect data against accidental, unauthorised or unlawful destruction, loss, alteration, disclosure or access.
          </li>
            </ol>
          </li>
          <li>
            <span>Assignment</span>
            <p className='definitionStyling'>The Client may not assign this Agreement without the prior written consent of TELUS which consent shall not be unreasonably withheld.</p>
          </li>
          <li>
            <span>Entire agreement</span>
            <p className='definitionStyling'>his Agreement supersedes all previous conditions understandings commitments agreements or representations (other than fraudulent misrepresentations) whatsoever whether oral or written relating to the subject matter hereof and constitutes the entire agreement between the parties relating to the subject matter hereof.</p>
          </li>
          <li>
            <span>Force majeure</span>
            <p className='definitionStyling'>Other than in respect of any payment obligation of the Client pursuant to this Agreement, neither party shall be in breach of this Agreement nor liable for delay in performing, or failure to perform, any of its obligations under this Agreement if such delay or failure result from, including, without limitation, strikes, work stoppages, accidents, acts of war or terrorism, civil or military disturbances, nuclear or natural catastrophes or acts of God, and interruptions, loss or malfunctions of utilities, communications or computer (software and hardware) services epidemics or events, circumstances or causes beyond its reasonable control.</p>
          </li>
          <li>
            <span>Waiver</span>
            <p className='definitionStyling'>No waiver of any term or condition of the Agreement shall be effective unless made in writing and signed by the party against which enforcement of the waiver is sought. The waiver of any breach of any term of any condition of the Agreement shall not be construed as a waiver of any subsequent breach of a term or condition of the same or a different nature.</p>
          </li>
          <li>
            <span>Severability</span>
            <p className='definitionStyling'>The invalidity, illegality or unenforceability of any of the provisions of this Agreement shall not affect the validity, legality and enforceability of the remaining provisions of this Agreement.</p>
          </li>
          <li>
            <span>No partnership</span>
            <p className='definitionStyling'>Nothing in this Agreement shall constitute or be construed as constituting or establishing any partnership or joint venture between the parties for any purpose whatsoever.</p>
          </li>
          <li>
            <span>Rights of third parties</span>
            <p className='definitionStyling'>Babylon and its affiliates are third party beneficiaries of this Agreement.</p>
          </li>
          <li>
            <span>Arbitration</span>
            <p className='definitionStyling'>TELUS and the Client shall use arbitration to resolve disputes between them arising from or relating to this Agreement, whether the dispute is based in contract, tort (including negligence), statute, or otherwise. If the business representatives of the parties have not been able to resolve any such dispute, in order to proceed with the dispute a Party must submit the dispute to private and confidential arbitration before a single arbitrator. Arbitration will take place in the capital of the province where the Client is located under the rules of the ADR Institute of Canada, including its rules as to initiation and submission of a dispute, appointment of an arbitrator, and responsibility for the fees and expenses arising from or relating to arbitration. This Section does not apply to the collection of any amounts owing to TELUS or any injunction application, except that, in the case of an injunction application, the Parties shall use arbitration to resolve the dispute that led to the application. Venue and jurisdiction of any court proceedings shall be in the province of British Columbia.</p>
          </li>
          <li>
            <span>Governing law</span>
            <p className='definitionStyling'>This Agreement, and any dispute or claim arising out of or in connection with it or its subject matter or formation (including non-contractual disputes or claims), shall be governed by, and construed in accordance with the law of British Columbia. Each each party irrevocably agrees that the courts of Vancouver, British Columbia shall have exclusive jurisdiction to settle such disputes or claims arising out of or in connection with this Agreement or its subject matter or formation (including non-contractual disputes or claims).</p>
          </li>
          <li>
            <span>Définitions</span>
          </li>
        </ol>
        <p><strong>“Agreement”</strong> means the agreement between TELUS and the Client in accordance with these terms and conditions and any terms set out in the Order Form.</p>
        <p><strong>“Babylon”</strong> means TELUS’ software provider Babylon Partners Limited</p>
        <p><strong>“Health Service Provider”</strong> means TELUS Health MyCare Inc., a subsidiary of TELUS Corporation.</p>
        <p><strong>“TELUS Health MyCareApp”</strong> means the virtual care application available for download from the Apple App Store or the Android Google Play Store providing free digital health tools and access to Insured Health Services and Premium Services.</p>
        <p><strong>“Eligible Employee(s)”</strong> means those members agreed between the parties and being an employee/staff member of the Client who is eligible to receive the Services.</p>
        <p><strong>“Dependants”</strong> means each Eligible Employee’s partner and their dependent children aged 25 years and under with the same permanent address as the Eligible Employee. Up to four Dependents for each Eligible Employee are eligible to receive the Services. Dependents over the age of 16 are eligible to receive the Premium Services.</p>
        <p><strong>“Eligibility List”</strong> means a list identifying all Eligible Employees in accordance with the value set out in the Order Form.</p>
        <p><strong>“Promotional Eligibility Requirements”</strong> means Eligible Employees provided with free access to Premium Services during the term and only while active subscribers on a TELUS Small Business Solutions Peace of Mind Plan, Simple Share Plan, and Owner's Advantage Plan.</p>
        <p><strong>“TELUS Health MyCare Business Solution”</strong> collectively means the TELUS Health MyCare App and the Employer Portal, as described in the Service Description.</p>
        <p><strong>“Employer Portal”</strong> means the portal that the Client will access to provide TELUS with a list of Eligible Employees.</p>
        <p><strong>“End User Terms”</strong> means the terms and conditions of use, including acceptance of the Privacy Policy, entered into between the Eligible Employee and the Health Service Provider which will be presented during the registration process on the TELUS Health MyCare App.</p>
        <p><strong>“Confidential Information”</strong> means non-public information in any form that is designated as confidential, or a reasonable person knows or reasonably should understand to be confidential (including without limitation, the terms of this Agreement and the fees). The following types of information, however marked, are not Confidential Information: that which (i) is, or becomes, publicly available without a breach of this Agreement; (ii) was lawfully known to the receiving Party without an obligation to keep it confidential; (iii) is received from another source who can disclose it lawfully and without an obligation to keep it confidential; or (iv) is independently developed.</p>
        <p><strong>“Insured Health Services”</strong> means consultations with physicians provided through the Digital Health App that are publically funded by provincial health care insurance plans.</p>
        <p><strong>“Premium Services”</strong> means access to the services of Health Practitioners made available through this agreement to the Client’s Eligible Employees and Dependents 16 years of age and older that are not Insured Health Services, as described in the Service Description, and may include additional Premium Services from time to time as agreed to by the parties</p>
        <p><strong>“Personal Information”</strong> means all information that is about an identifiable individual, including any personal health information.</p>
        <p><strong>“Privacy Laws”</strong> means all federal, provincial and territorial legislation, regulations, and decisions, as amended or supplemented from time to time, now in force or that may in the future come into force in Canada, governing the protection of Personal Information in the private and public sector, applicable to Client or TELUS in relation to the Services or obligations under this Agreement, and includes the Personal Information Protection and Electronic Documents Act (PIPEDA) (Canada).</p>
        <p><strong>“Services”</strong> means the services provided through the TELUS Health MyCare Business Solution as described in the Service Description Section below and also includes the clinical services as governed by the End User Terms.</p>
        <p><strong>"Start Date"</strong> is defined as the first date on which the Client has access to the Employer Portal</p>
        <h2 style={{ marginTop: '40px' }}>Service Description</h2>
        <p>The TELUS Health MyCare Business Solution provides services to both Eligible Employees and to the Client.</p>
        <p>TELUS Health MyCare Business Solution is comprised of the following:</p>
        <p style={{ margin: '0 0 0 30px' }}>1-<span style={{ marginLeft: '15px' }}>TELUS Health MyCare  App</span></p>
        <ul style={{ margin: '0 0 0 72px' }}>
          <li>Eligible employees can download and register for the TELUS Health MyCare App for free at any time.</li>
          <li>Free access to Insured Health Services</li>
          <li>Paid access to Premium Services</li>
        </ul>
        <p style={{ margin: '0 0 0 30px' }}>2-<span style={{ marginLeft: '15px' }}>Employer Portal</span></p>
        <ul style={{ margin: '0 0 0 72px' }}>
          <li>The Client will be provided with a unique access code to validate Eligible Employees and Dependents.The Client will provide the unique access code only to Eligible Employees and Dependents which unlocks access to Premium Services through the TELUS Health MyCare including:
        <ul style={{ margin: '0 0 0 25px', listStyle: 'circle' }}>
              <li>Up to 12 one-on-one video appointments with mental health counsellors for each Eligible Employee and Dependent per year at no additional cost. If users exceed 12 appointments during the Term, a discounted rate of $100 per appointment will apply, paid by the user.</li>
              <li>Access to additional services that may become available in the future through existing fee payments or at additional cost to the Client</li>
            </ul>
          </li>
        </ul>
      </div>
    </TermsAndConditions>
  );
};

export default Terms;
