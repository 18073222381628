import React from 'react';
import './styles.css';
import { TermsAndConditions } from '../../styles/elements';

const Terms = () => {
  return (
    <TermsAndConditions>
      <div className='agreement' id="termsAndConditions">
        <p>
          Les détails de la commande résument les frais relatifs aux services que vous avez commandés auprès de TELUS à la signature du présent contrat. Le Formulaire de commande et les modalités et conditions ci-dessous constituent le contrat entre TELUS et le Client.
        </p>
        <p>
          Les présentes modalités et conditions sont établies entre TELUS Communications (« TELUS ») et le Client, à savoir l’entité juridique indiquée sur le Formulaire de commande.
        </p>
        <p>
          TELUS convient de vous fournir les Services (décrits à la section Description des services ci-dessous). Le présent document énonce les modalités convenues entre vous et TELUS concernant ces Services.
        </p>
        <p>
          En cliquant sur « J’ACCEPTE » ou en procédant à une forme d’acceptation similaire par voie électronique, vous acceptez les présentes Modalités et conditions générales, qui constituent une entente juridiquement contraignante entre vous et TELUS.  Si vous refusez d’être lié par les présentes Modalités et conditions générales, veuillez ne pas utiliser la Solution Mes soins TELUS Santé Affaires.
        </p>
        <p>
          Le présent contrat inclut les autres documents décrits dans celui-ci ou joints à celui-ci au moment de sa signature; mais il exclut toute autre déclaration ou tout autre document. Le présent contrat remplace les ententes antérieurement conclues entre vous et TELUS en ce qui a trait aux services visés par le présent contrat.
        </p>

        <ol className='mainList'>
          <li>Services
        <ol className='outerList'>
              <li>
                Le Client reconnaît a) que les Services (décrits à la section Description des services ci-dessous) aux Employés admissibles sont fournis par le Fournisseur de services de santé par l’entremise de la Solution Mes soins TELUS Santé Affaires et que cette utilisation est régie par les Modalités des utilisateurs finaux et b) que l’utilisation de la Solution Mes soins TELUS Santé Affaires par les Employés admissibles et les Personnes à charge, ainsi que la fourniture de services ou de produits par l’entremise de la Solution Mes soins TELUS Santé Affaires, exigent que les Employés admissibles et les Personnes à charge conviennent des Modalités des utilisateurs finaux avec le Fournisseur de services de santé.
              </li>
              <li>
                Le Client reconnaît et convient que la Solution Mes soins TELUS Santé Affaires peut être modifiée de temps à autre afin de respecter toute loi ou exigence de sécurité applicable, ou de toute manière que le Fournisseur de services de santé ou ses sociétés affiliées jugent (à leur entière discrétion) raisonnable ou souhaitable, dans la mesure où le Client n’est pas obligé de payer des Services ou des produits qui n’ont pas été fournis à un Employé admissible.
              </li>
              <li>
                Le Client convient :
            <ol className='innerList'>
                  <li>
                    de fournir à TELUS une liste des Employés admissibles et Personnes à charge, (la « Liste d’admissibilité ») dans les dix (10) jours suivant la Date de début du Contrat. Le Client est aussi obligé de maintenir l’exactitude de la Liste d’admissibilité et d’informer TELUS de toute modification apportée à celle-ci dans les plus brefs délais, mais au plus tard 30 jours après l’ajout ou le retrait d’employés et de personnes à charge sur la Liste d’admissibilité.
                  </li>
                  <li>
                    de collaborer avec TELUS à tous les égards, sur demande raisonnable, à la validation des Employés admissibles et de veiller à ce que seules les personnes appropriées soient considérées comme des Employés admissibles et des Personnes à charge, notamment en tenant à jour la Liste d’admissibilité, en avisant TELUS sans tarder si un Employé admissible ou une Personne à charge cesse d’être autorisé à utiliser la Solution Mes soins TELUS Santé Affaires et en répondant rapidement à toute demande de validation d’utilisateurs de la part de TELUS;
                  </li>
                  <li>
                    de collaborer avec TELUS à des audits ponctuels de la liste;
                  </li>
                  <li>
                    de prendre toutes les mesures raisonnables pour promouvoir la Solution Mes soins TELUS Santé Affaires auprès de ses employés et de les encourager à utiliser la Solution Mes soins TELUS Santé Affaires;
                  </li>
                  <li>
                    d’informer sans tarder TELUS des plaintes, événements indésirables ou commentaires que le Client reçoit de la part des Employés admissibles ou dont il prend connaissance à l’égard des Services, de la Solution Mes soins TELUS Santé Affaires ou de l’Application Mes Soins TELUS Santé.
                  </li>
                </ol>
              </li>
            </ol>
          </li>
          <li>Frais et paiement
        <ol className='outerList'>
              <li>
                Le Client doit payer à TELUS les Frais totaux associés aux Services supérieurs conformément aux Modalités de paiement énoncées dans le Formulaire de commande. Le Client reçoit sa facture mensuelle par la poste ou en ligne. Tous les montants dus à TELUS sont exigibles le jour de la réception de la facture s’il s’agit d’une facture papier de TELUS ou le jour de sa publication en ligne s’il s’agit d’une facture électronique. Vous pouvez vous inscrire aux avis de facture électronique afin de recevoir un courriel ou un texto vous informant que votre facture a été publiée en ligne. Votre facture vous indique le montant à payer et la date à laquelle le montant doit être reçu par TELUS pour éviter des frais de paiement tardif.
              </li>
              <li>TELUS peut modifier le Prix par Employé admissible en remettant un avis au Client au moins un mois à l’avance.</li>
              <li>
                Si TELUS établit qu’un Employé admissible se trouvant sur la Liste d’admissibilité dépasse le nombre indiqué sur le Formulaire de commande et qu’elle n’en a pas été avisée, TELUS produit une facture en conséquence.
              </li>
              <li>
                Le Client peut ajouter des Employés admissibles ou des Personnes à charge à tout moment au cours de la Durée du contrat en avisant TELUS dans les 30 jours. Les nouveaux Employés admissibles figurent sur la prochaine facture mensuelle, le cas échéant.
              </li>
              <li>
                Si TELUS établit qu’un Employé admissible se trouvant sur la Liste d’admissibilité dépasse le nombre indiqué sur le Formulaire de commande et qu’elle n’en a pas été avisée, TELUS produit une facture en conséquence.              </li>
              <li>
                Si TELUS établit qu’un Employé admissible ou une Personne à charge se trouvant sur la Liste d’admissibilité ne respecte plus les Critères d’admissibilité à l’offre promotionnelle, TELUS produit une facture en conséquence.              </li>
              <li>
                Tous les montants à payer par le Client en vertu du Contrat sont exempts de taxes, droits de douane ou autres, tarifs ou autres taxes similaires. Les montants en souffrance portent intérêt au taux de 2 % par mois, soit 26,8 % par année.              </li>
              <li>
                Le Client doit payer tous les montants dus en vertu du Contrat intégralement, sans compensation, demande reconventionnelle, déduction ou retenue (sauf toute déduction ou retenue exigée par la loi).
              </li>
            </ol>
          </li>
          <li>Droits de propriété intellectuelle
        <ol className='outerList'>
              <li>
                Entre le Client d’une part, et TELUS et ses partenaires (y compris Babylon) d’autre part, TELUS et ses partenaires (y compris Babylon) détiennent et conservent tous les droits de propriété intellectuelle actuels et futurs (et toutes les demandes, déclarations et inscriptions connexes) relatifs aux Services, à l’Application Mes Soins TELUS Santé, à la Solution Mes soins TELUS Santé Affaires, à la documentation et à tous les éléments susceptibles de bénéficier d’une protection légale, aux œuvres dérivées, aux modifications et aux améliorations apportées à l’ensemble des éléments précités, que ces éléments soient développés ou non avec le Client, ou qu’ils soient développés ou non par TELUS et ses partenaires (y compris Babylon), le Client ou tout entrepreneur, sous-traitant ou mandataire de TELUS et ses partenaires (y compris Babylon) ou du Client. Rien dans le Contrat ne peut provoquer le transfert de propriété ou l’octroi d’une licence au Client en vertu des droits de propriété intellectuelle de TELUS, de Babylon ou des sociétés affiliées de Babylon.              </li>
            </ol>
          </li>
          <li>Confidentialité
        <ol className='outerList'>
              <li>
                Chaque partie s’engage à ne pas divulguer d’Information confidentielle à quiconque, sauf dans la mesure permise par le paragraphe 4.2.              </li>
              <li>
                Chaque partie peut divulguer l’Information confidentielle de l’autre partie :
            <ol className='innerList'>
                  <li>
                    à ses employés, dirigeants, représentants, sous-traitants ou conseillers qui ont besoin de la connaître pour exécuter les obligations de la partie en vertu du Contrat. Chaque partie doit s’assurer que ses employés, dirigeants, représentants, sous-traitants ou conseillers à qui elle divulgue l’Information confidentielle de l’autre partie se conforment au présent article 4; ou                  </li>
                  <li>
                    si la loi, un tribunal compétent ou un organisme de réglementation l’exige, dans la mesure où elle avise l’autre partie avant de procéder à la divulgation, à moins qu’une loi ou un règlement l’interdise.                  </li>
                </ol>
              </li>
              <li>
                Aucune partie ne doit utiliser l’Information confidentielle de l’autre partie à toute fin autre que l’exercice de ses droits ou l’exécution de ses obligations en vertu du Contrat. Pour éviter toute ambiguïté, TELUS doit divulguer l’Information confidentielle du Client à Babylon dans la mesure nécessaire pour exercer ses droits et exécuter ses obligations en vertu du Contrat.              </li>
            </ol>
          </li>
          <li>Limitation de responsabilité
        <ol className='outerList'>
              <li>
                Dénégation de garantie. Sauf dans la mesure expressément indiquée aux présentes et dans la mesure permise par la loi, les Services, la Solution Mes soins TELUS Santé Affaires, ainsi que la totalité des données (y compris les données de tiers), de l’information ou des services consultés, utilisés ou fournis en lien avec le Contrat sont fournis « tels quels » et « sous réserve de leur disponibilité ». TELUS, Babylon et les sociétés affiliées de Babylon n’accordent aucune garantie, expresse ou tacite, y compris aucune garantie tacite de valeur marchande, d’exactitude, d’intégrité, de libre-exploitation, de non-contrefaçon ou d’adéquation à un usage particulier, découlant des modalités d’exécution ou des pratiques commerciales courantes, ou encore voulant que les Services ou l’application soient exempts d’erreurs ou soient fonctionnels en permanence.              </li>
              <li>
                Limitation de responsabilité. TELUS, Babylon et leurs sociétés affiliées ne peuvent en aucun cas être tenues responsables envers le Client ou toute autre entité, en lien avec le Contrat, a) de dommages particuliers, indirects, accessoires ou consécutifs, b) de toute perte de données ou de profits ou de toute interruption des activités découlant du Contrat, qu’elle qu’en soit la cause, qu’elle ait ou non été prévisible et que les parties aient été avisées ou non de la possibilité de tels dommages, c) d’un montant cumulatif supérieur aux Frais payés par le Client à TELUS dans les six (6) mois précédant l’événement à l’origine de la réclamation. Les parties comprennent et acceptent cette répartition des responsabilités et des risques.              </li>
            </ol>
          </li>
          <li>Résiliation
        <ol className='outerList'>
              <li>
                À moins qu’il soit résilié par l’une des parties conformément au paragraphe 6.2 ou par TELUS conformément au paragraphe 6.3, le Contrat entre en vigueur le jour de sa signature et se renouvelle automatiquement pour des périodes successives de un (1) mois jusqu’à ce qu’il soit résilié par l’une des parties moyennant un préavis d’au moins trente (30) jours à l’autre partie, après lequel le Contrat est résilié lors du prochain anniversaire mensuel de la Date de début tombant après la période minimale du préavis.               </li>
              <li>
                Sans porter atteinte à tout autre droit ou recours dont elle peut se prévaloir, l’une ou l’autre des parties peut résilier le Contrat avec effet immédiat en remettant un avis écrit à l’autre partie si :            <ol className='innerList'>
                  <li>
                    l’autre partie commet un manquement important à toute disposition du Contrat et (si ce manquement peut être corrigé) ne corrige pas ce manquement dans un délai de trente (30) jours après avoir été avisée de le faire;                  </li>
                  <li>
                    l’autre partie prend toute mesure en vue de son administration, de sa liquidation provisoire ou d’un concordat ou d’un arrangement avec ses créanciers (sauf une restructuration en vue d’un retour à la solvabilité), de sa dissolution (volontaire ou ordonnée par un tribunal, sauf aux fins d’une restructuration en vue d’un retour à la solvabilité), de la nomination d’un séquestre désigné à l’égard de n’importe lequel de ses actifs ou de la cessation de ses activités ou, si la mesure est prise dans un autre territoire, en vue d’une procédure analogue dans le territoire concerné;
                  </li>
                  <li>
                    l’autre partie suspend ou menace de suspendre, ou encore cesse ou menace de cesser la totalité ou une partie importante de ses activités.                  </li>
                </ol>
              </li>
              <li>
                Sans porter atteinte à tout autre droit ou recours dont elle peut se prévaloir, TELUS peut résilier ou suspendre le Contrat avec effet immédiat en remettant un préavis écrit de soixante (60) jours au Client.
              </li>
            </ol>
          </li>
          <li>Conséquences de la résiliation
        <ol className='outerList'>
              <li>
                Advenant la résiliation du Contrat pour quelque raison que ce soit :
            <ol className='innerList'>
                  <li>
                    le Client doit payer immédiatement à TELUS toutes les factures impayées de celle-ci et les intérêts afférents et, en ce qui concerne les Services fournis qui n’ont pas été facturés, TELUS doit soumettre une facture, qui sera payable par le Client dès sa réception;                  </li>
                  <li>
                    le Client doit détruire tout le matériel de marketing et tous les autres éléments connexes que TELUS lui a fournis pendant la Durée du Contrat;                  </li>
                  <li>
                    sans porter atteinte aux autres droits prévus dans le Contrat, chaque partie doit retourner à l’autre partie toute l’Information confidentielle de l’autre partie en sa possession ou sous son contrôle ou, au gré de la partie divulgatrice, détruire cette Information confidentielle, y compris les copies ou les reproductions de celle-ci, et confirmer cette destruction par écrit. La présente disposition ne s’applique pas aux copies électroniques effectuées dans le cadre de la procédure de sauvegarde informatique standard de la partie réceptrice, qui restent assujetties à l’article 4;                  </li>
                  <li>
                    TELUS doit cesser immédiatement de permettre aux Employés admissibles et aux Personnes à charge d’utiliser la Solution Mes soins TELUS Santé Affaires;                  </li>
                  <li>
                    les recours, les obligations et les responsabilités des parties au moment de l’expiration ou de la résiliation demeurent inchangés, y compris le droit de demander des dommages-intérêts à l’égard de tout manquement au Contrat qui existait au moment de l’expiration ou de la résiliation ou avant celle-ci;                  </li>
                  <li>les articles 4, 5, 6, 8 et 10 à 19 restent pleinement en vigueur.</li>
                </ol>
              </li>
            </ol>
          </li>
          <li>Vie privée
        <ol className='outerList'>
              <li>
                TELUS et le Client doivent se conformer à leurs obligations respectives en vertu des Lois sur la protection de la vie privée. En ce qui concerne les Renseignements personnels transférés à TELUS ou encore mis autrement à sa disposition par le Client ou par un tiers au nom de ce dernier, le Client déclare et certifie avoir le pouvoir de faire en sorte, et (ou) avoir obtenu des Employés admissibles tous les consentements exigés par les Lois sur la protection de la vie privée applicables pour faire en sorte, que les Renseignements personnels soient transférés, divulgués, traités, copiés, modifiés, stockés, supprimés ou utilisés de toute autre manière par TELUS pour la fourniture des Services et aux fins indiquées dans le Contrat. TELUS dispose de mesures physiques, techniques et organisationnelles raisonnables afin de protéger les données contre toute destruction, perte, modification, divulgation ou consultation accidentelle, non autorisée ou illicite.              </li>
            </ol>
          </li>
          <li>
            <span>Cession</span>
            <p className='definitionStyling'>
              Le Client ne peut céder le Contrat sans le consentement écrit préalable de TELUS, que celle-ci ne peut refuser de lui accorder sans motif raisonnable.            </p>
          </li>
          <li>
            <span>Entente intégrale </span>
            <p className='definitionStyling'>
              Le Contrat remplace la totalité des conditions, conventions, ententes, déclarations (autres que les déclarations frauduleuses) ou engagements antérieurs, de nature verbale ou écrite, se rapportant à l’objet des présentes et constitue l’entente intégrale entre les parties en ce qui a trait à l’objet des présentes.            </p>
          </li>
          <li>
            <span>Force majeure</span>
            <p className='definitionStyling'>
              Sauf à l’égard de toute obligation de paiement du Client en vertu du Contrat, aucune des parties ne viole le Contrat ni ne peut être tenue responsable de tout retard ou manquement dans l’exécution de ses obligations en vertu du Contrat si ce retard ou ce manquement est attribuable notamment à l’une des causes suivantes : grèves, arrêts de travail, accidents, actes de guerre ou de terrorisme, perturbations civiles ou militaires, catastrophes naturelles ou nucléaires ou cas fortuits, interruption, perte ou panne de services publics, de systèmes de communication ou de systèmes informatiques (logiciels et matériel), épidémies ou circonstances, causes ou événements indépendants de la volonté raisonnable des parties.            </p>
          </li>
          <li>
            <span>Renonciation</span>
            <p className='definitionStyling'>
              Aucune renonciation à une modalité ou condition du Contrat n’est valable, à moins d’être effectuée par écrit et signée par la partie à l’encontre de laquelle l’application de la renonciation est demandée. La renonciation à sanctionner tout manquement ou la renonciation à une modalité ou condition du Contrat ne doit pas être interprétée comme une renonciation à sanctionner tout manquement ou une renonciation à toute modalité ou condition subséquente, de même nature ou de nature différente.            </p>
          </li>
          <li>
            <span>Dissociabilité</span>
            <p className='definitionStyling'>
              La non-validité, l’illégalité ou le caractère inexécutoire d’une disposition du Contrat ne porte pas atteinte à la validité, à la légalité ou au caractère exécutoire des autres dispositions du Contrat.            </p>
          </li>
          <li>
            <span>Indépendance</span>
            <p className='definitionStyling'>
              Rien dans le Contrat ne constitue ou ne doit être interprété comme constituant un partenariat ou une coentreprise entre les parties à quelque fin que ce soit.            </p>
          </li>
          <li>
            <span>Droits des tiers</span>
            <p className='definitionStyling'>
              Babylon et ses sociétés affiliées sont des tiers bénéficiaires du Contrat.
            </p>
          </li>
          <li>
            <span>Arbitrage</span>
            <p className='definitionStyling'>
              TELUS et le Client doivent avoir recours à l’arbitrage pour régler leurs différends découlant du Contrat ou encore liés à celui-ci, peu importe que ces différends reposent sur une base contractuelle, extracontractuelle (y compris la négligence), législative ou autre. Si les représentants des parties ne parviennent pas à régler un tel différend, l’une des parties doit, en vue de son règlement, le soumettre à un arbitrage privé et confidentiel mené par un seul arbitre. L’arbitrage doit se dérouler dans la capitale de la province où se situe le Client conformément aux règles de l’Institut d’arbitrage et de médiation du Canada inc., y compris ses règles relatives à la soumission d’un différend à l’arbitrage, à la nomination d’un arbitre, ainsi qu’à la responsabilité à l’égard des frais et dépenses consécutifs ou liés à l’arbitrage. Le présent article ne s’applique pas au recouvrement des montants dus à TELUS. Il ne s’applique pas non plus aux demandes d’injonction, les parties pouvant toutefois avoir recours à l’arbitrage pour résoudre tout différend ayant conduit à une demande d’injonction. Toute procédure judiciaire liée au Contrat doit être engagée devant un tribunal compétent de la Colombie-Britannique.            </p>
          </li>
          <li>
            <span>Droit applicable</span>
            <p className='definitionStyling'>
              Le Contrat, ainsi que tout différend ou toute réclamation découlant de celui-ci, de son objet ou de sa formation (y compris les réclamations ou les litiges non contractuels), est régi par les lois de la Colombie-Britannique et doit être interprété conformément à celles-ci. Chaque partie se soumet irrévocablement à la compétence exclusive des tribunaux de Vancouver, en Colombie-Britannique, aux fins du règlement des différends ou des réclamations découlant du Contrat, de son objet ou de sa formation (y compris les réclamations ou les litiges non contractuels).            </p>
          </li>
          <li>
            <span>Définitions</span>
          </li>
        </ol>
        <p>
          <strong>« Contrat »</strong>
          signifie le contrat entre TELUS et le Client conformément aux présentes modalités et conditions et aux modalités énoncées dans le Formulaire de commande.        </p>
        <p>
          <strong>« Babylon »</strong>
          signifie Babylon Partners Limited, le fournisseur du logiciel à TELUS
            </p>
        <p>
          <strong>« Fournisseur de services de santé »</strong>
          signifie Mes Soins TELUS Santé inc., une filiale de TELUS Corporation.
        </p>
        <p>
          <strong>
            « Application Mes Soins TELUS Santé »
          </strong>
          signifie l’application de soins de santé virtuels qui peut être téléchargée de la boutique App Store d’Apple ou de la boutique Google Play Store et qui fournit des outils de santé numériques gratuits et un accès aux Services de santé assurés et aux Services supérieurs.        </p>
        <p>
          <strong>
            « Employés admissibles »
          </strong>
          signifie les membres dont l’admissibilité aux Services a été convenue par les parties et qui sont des employés du Client.        </p>
        <p>
          <strong>
            « Personnes à charge »
          </strong>
          signifie le conjoint ou la conjointe de chaque Employé admissible et leurs enfants à charge âgés de 25 ans ou moins et vivant à la même adresse permanente que l’Employé admissible. Un maximum de quatre Personnes à charge par Employé admissible peuvent recevoir les Services. Les Personnes à charge de 16 ans et plus sont admissibles aux Services supérieurs.        </p>
        <p>
          <strong>
            « Liste d’admissibilité »
          </strong>
          signifie une liste qui répertorie tous les Employés admissibles, conformément au nombre indiqué dans le Formulaire de commande.          </p>
        <p>
          <strong>
            « Critères d’admissibilité à l’offre promotionnelle »
          </strong>
          signifie les critères en vertu desquels les Employés admissibles peuvent obtenir un accès gratuit aux Services supérieurs pendant la Durée du Contrat et seulement s’ils sont des abonnés actifs à un forfait Sans tracas, Partage facile ou Avantage du propriétaire de Solutions aux PME de TELUS.        </p>
        <p>
          <strong>
            « Solution Mes soins TELUS Santé Affaires »
          </strong>
          signifie collectivement l’Application Mes Soins TELUS Santé et le Portail des employeurs, décrits à la section Description des services.          </p>
        <p>
          <strong>
            « Portail des employeurs »
          </strong>
          signifie le portail auquel le Client accède pour fournir une liste des Employés admissibles à TELUS.
          </p>
        <p>
          <strong>
            « Modalités des utilisateurs finaux »
          </strong>
          signifie les modalités et conditions d’utilisation, y compris l’acceptation de la Politique de protection de la vie privée, convenues entre l’Employé admissible et le Fournisseur de services de santé, qui s’affichent dans l’Application Mes Soins TELUS Santé pendant le processus d’inscription.          </p>
        <p>
          <strong>
            « Information confidentielle »
          </strong>
          signifie l’information non publique, sous quelque forme que ce soit, qui est désignée confidentielle ou qu’une personne raisonnable devrait savoir ou percevoir qu’elle est confidentielle (y compris, sans s’y limiter, les modalités du Contrat et les frais). Les types d’information suivants, peu importe leur désignation, ne constituent pas de l’Information confidentielle : l’information qui i) est ou devient accessible au public sans manquement au Contrat, ii) était légitimement connue de la partie réceptrice, laquelle n’est pas assujettie à une obligation de confidentialité, iii) est reçue d’une autre source qui peut la divulguer de façon légitime et qui n’est pas assujettie à une obligation de confidentialité ou iv) a été élaborée de façon indépendante.          </p>
        <p>
          <strong>
            « Services de santé assurés »
          </strong>
          signifie les consultations avec des médecins effectuées par l’intermédiaire de, Application Mes soins TELUS Santé et qui sont financées par les régimes publics d’assurance-maladie provinciaux.          </p>
        <p>
          <strong>
            « Services supérieurs »
          </strong>
          signifie les services de Professionnels de la santé auxquels les Employés admissibles du Client et leurs Personnes à charge âgées de 16 ans et plus ont accès en vertu du Contrat, qui ne sont pas des Services de santé assurés tels qu’ils sont décrits à la section Description des services et qui peuvent comprendre des Services supérieurs supplémentaires convenus par les parties de temps à autre.          </p>
        <p>
          <strong>
            « Renseignements personnels »
          </strong>
          signifie tous les renseignements au sujet d’une personne identifiable, y compris les renseignements personnels sur la santé.          </p>
        <p>
          <strong>
            « Lois sur la protection de la vie privée »
          </strong>
          signifie l’ensemble des lois, des règlements et des décisions des gouvernements fédéral, provinciaux et territoriaux, dans leur version modifiée ou complétée de temps à autre, actuellement en vigueur ou pouvant entrer en vigueur ultérieurement au Canada, qui régit la protection des Renseignements personnels dans le secteur public et privé, qui s’applique au Client ou à TELUS en ce qui concerne les Services ou les obligations prévues au Contrat, et qui comprend la Loi sur la protection des renseignements personnels et les documents électroniques (LPRPDE).          </p>
        <p>
          <strong>
            « Services »
          </strong>
          signifie les services fournis par l’entremise de la Solution Mes soins TELUS Santé Affaires, tels qu’ils sont décrits à la section Description des services ci-dessous, et comprend aussi les services cliniques régis par les Modalités des utilisateurs finaux.          </p>
        <p>
          <strong>
            « Date de début »
          </strong>
          signifie la première date à laquelle le Client a accès au Portail des employeurs.
        </p>

        <h2 style={{ marginTop: '40px' }}>Description des services</h2>
        <p>La Solution Mes soins TELUS Santé Affaires fournit des services à la fois aux Employés admissibles et au Client.</p>
        <p>La Solution Mes soins TELUS Santé Affaires comprend ce qui suit :</p>
        <p style={{ margin: '0 0 0 30px' }}>1-<span style={{ marginLeft: '15px' }}>Application Mes Soins TELUS Santé</span></p>
        <ul style={{ margin: '0 0 0 72px' }}>
          <li>Les Employés admissibles peuvent télécharger l’Application Mes Soins TELUS Santé et s’y inscrire sans frais en tout temps. </li>
          <li>Elle procure un accès gratuit aux Services de santé assurés.</li>
          <li>Elle procure un accès payant aux Services supérieurs.</li>
        </ul>
        <p style={{ margin: '0 0 0 30px' }}>2-<span style={{ marginLeft: '15px' }}>Portail des employeurs</span></p>
        <ul style={{ margin: '0 0 0 72px' }}>
          <li>
            Le Client obtient un code d’accès unique pour valider les Employés admissibles et les Personnes à charge. Le Client fournit ensuite le code d’accès unique aux Employés admissibles et à leurs Personnes à charge pour leur permettre de déverrouiller l’accès aux Services supérieurs par l’intermédiaire de Mes Soins TELUS Santé, ce qui comprend :          <ul style={{ margin: '0 0 0 25px', listStyle: 'circle' }}>
              <li>jusqu’à 12 consultations vidéo individuelles par année avec un professionnel en santé mentale pour chaque Employé admissible et ses Personnes à charge, sans frais supplémentaires. Si un utilisateur excède la limite de 12 consultations pendant la Durée du contrat, un taux réduit de 100 $ par consultation s’applique, payé par l’utilisateur.</li>
              <li>des services supplémentaires qui pourraient être offerts ultérieurement, moyennant les frais existants ou des frais supplémentaires pour le Client.</li>
            </ul>
          </li>
        </ul>
      </div>
    </TermsAndConditions>
  );
};

export default Terms;
