import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@tds/core-tooltip';
import Notification from '@tds/core-notification';
import Box from '@tds/core-box';
import Text from '@tds/core-text'
import { useTranslation } from 'react-i18next';

import Field from '../Form/Field';

const CommonFields = ({ touched, errors, showTooltip, showMobilePhoneNote }) => {
  const [t] = useTranslation();
  return (
    <>
      <Field
        name="firstName"
        label={t('FIRST_NAME')}
        touched={touched}
        errors={errors}
      />
      <Field
        name="lastName"
        label={t('LAST_NAME')}
        touched={touched}
        errors={errors}
      />
      <Field
        name="email"
        type="email"
        label={t('EMAIL')}
        touched={touched}
        errors={errors}
      />
      <Field
        name="mobileNumber"
        type="mask"
        label={t('MOBILE_NUMBER')}
        touched={touched}
        errors={errors}
        hint={showMobilePhoneNote && <><Text bold>{t('NOTE')}:</Text> {t('MOBILE_TOOLTIP')}</>}
        hintPosition="below"
      />
    </>
  );
};



CommonFields.defaultProps ={
  showTooltip: true,
}
CommonFields.propTypes = {
  touched: PropTypes.objectOf(PropTypes.any).isRequired,
  errors: PropTypes.objectOf(PropTypes.any).isRequired,
  showTooltip: PropTypes.bool,
};

export default CommonFields;
