import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import LogoEn from '../../images/Logo_TELUS_en.png';
import LogoFr from '../../images/Logo_TELUS_fr.png';
import { languageTypes } from '../../config/constants';

const localizedFileName = {
  [languageTypes.en]: LogoEn,
  [languageTypes.fr]: LogoFr
};

const Logo = ({ height }) => {
  const [t, i18next] = useTranslation();
  const logoImg = localizedFileName[i18next.language];

  return (
    <img style={{ height: height }} src={logoImg} alt={t('LOGO_ALT')} />
  );
};

Logo.propTypes = {
  height: PropTypes.string
};

Logo.defaultProps = {
  height: 'auto'
};

export default Logo;