import React from 'react';
import { useTranslation } from 'react-i18next';
import { languageTypes } from '../../config/constants';

import EnTerms from './En';
import FrTerms from './Fr';

const localizedFileName = {
  [languageTypes.en]: EnTerms,
  [languageTypes.fr]: FrTerms,
};

const Terms = ({setIsScrolled}) => {
  const [t, i18next] = useTranslation();
  const TermsAndConditions = localizedFileName[i18next.language];

  const isBottom = (el) => {
    return el.getBoundingClientRect().bottom <= window.innerHeight;
  }

  const trackScrolling = () => {
    const wrappedElement = document.getElementById('termsAndConditions');
    if (isBottom(wrappedElement)) {
      setIsScrolled(true)
      document.removeEventListener('scroll', trackScrolling);
    }
  };

  return (
    <div onScroll={() => {trackScrolling()}}>
      <TermsAndConditions/>
    </div>
  );
};

export default Terms;
