import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import Input from '@tds/core-input';
import Checkbox from '@tds/core-checkbox';
import Box from '@tds/core-box';
import Radio from '@tds/core-radio';
import Text from '@tds/core-text';
import InputMask from 'react-input-mask';
import Select from '@tds/core-select';
import { useTranslation } from 'react-i18next';

import FormCol from './FormCol';

const CustomField = ({
  desciptiveText,
  note,
  name,
  label,
  type,
  touched,
  errors,
  tooltip,
  onChange: onFieldChange,
  options,
  placeholder,
  disabled,
  isNewStep,
  hint,
  hintPosition
}) => {
  const [t] = useTranslation();
  const formatOptionLabel = (option) => (option).charAt(0).toUpperCase() + (option).slice(1).toLocaleLowerCase();
  return (
    <FormCol>
      <Field name={name}>
        {({ field: { name: fieldName, onBlur, onChange, value } }) => {
          if (name === `totalPerMonth`) {
            value = value ? `$${value}` : value;
          }
          return (
            <>
              {(type === 'checkbox' && (
                <Checkbox
                  label={label}
                  type={type}
                  error={touched[name] && errors[name]}
                  feedback={touched[name] && errors[name] && 'error'}
                  value={value || ''}
                  checked={value}
                  name={fieldName}
                  onBlur={onBlur}
                  onChange={(e) => {
                    onChange(e);
                    onFieldChange(e);
                  }}
                />
              )) ||
                (type === 'radio' && (
                  <Box tag="fieldset" between={2}>
                    <legend>
                      <Text bold size="medium">
                        {label}
                      </Text>
                    </legend>
                    {
                      desciptiveText &&
                      <div style={{ marginTop: -10 }}>
                        <Text size='small' bold>
                          {note} <Text size='small'>{desciptiveText}</Text>
                        </Text>
                      </div>
                    }
                    {options &&
                      options.map((option) => (
                        <Radio
                          key={option}
                          label={isNewStep ? formatOptionLabel(option) : option}
                          name={name}
                          value={option}
                          checked={t(value) === option}
                          onChange={onChange}
                        />
                      ))}
                  </Box>
                )) ||
                (type === 'mask' && (
                  <InputMask
                    label={label}
                    name={fieldName}
                    mask="(999) 999-9999"
                    value={value || ''}
                    onBlur={onBlur}
                    onChange={(e) => {
                      onChange(e);
                      onFieldChange(e);
                    }}
                    hint={hint}
                    hintPosition={hintPosition}
                  >
                    {({
                      value: maskValue,
                      onChange: onMaskChange,
                      name: maskName,
                    }) => (
                        <Input
                          name={maskName}
                          value={maskValue}
                          onChange={onMaskChange}
                          error={touched[name] && errors[name]}
                          feedback={touched[name] && errors[name] && 'error'}
                          type="text"
                          label={label}
                          tooltip={tooltip}
                          hint={hint}
                          hintPosition={hintPosition}
                        />
                      )}
                  </InputMask>
                )) ||
                (type === 'select' && (
                  <Select
                    label={label}
                    error={touched[name] && errors[name]}
                    feedback={touched[name] && errors[name] && 'error'}
                    name={fieldName}
                    onBlur={onBlur}
                    onChange={(e) => {
                      onChange(e);
                      onFieldChange(e);
                    }}
                    tooltip={tooltip}
                    value={value || ''}
                    options={options}
                    placeholder={placeholder || ''}
                  />
                )) || (
                  <Box>
                    <Text bold>
                      {label}
                    </Text>
                    {
                      desciptiveText &&
                      <div style={{ marginTop: 0 }}>
                        <Text size='small' bold>
                          {note} <Text size='small'>{desciptiveText}</Text>
                        </Text>
                      </div>
                    }
                    <Input
                      // label={label}
                      type={type}
                      error={touched[name] && errors[name]}
                      feedback={touched[name] && errors[name] && 'error'}
                      name={fieldName}
                      onBlur={onBlur}
                      onChange={(e) => {
                        onChange(e);
                        onFieldChange(e);
                      }}
                      tooltip={tooltip}
                      value={value || ''}
                      options={options}
                      disabled={disabled}
                    />
                  </Box>

                )}
            </>
          );
        }}
      </Field>
    </FormCol>
  );
};

CustomField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  touched: PropTypes.objectOf(PropTypes.any).isRequired,
  errors: PropTypes.objectOf(PropTypes.any).isRequired,
  tooltip: PropTypes.node,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.any),
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  desciptiveText: PropTypes.string,
  note: PropTypes.string,
};

CustomField.defaultProps = {
  type: 'text',
  tooltip: undefined,
  onChange: () => { },
  options: [],
  disabled: false,
};

export default CustomField;
