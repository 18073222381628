import React, { useState, useEffect } from 'react';
import Button from '@tds/core-button';
import FlexGrid from '@tds/core-flex-grid';
import Text from '@tds/core-text';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { getStepTwoSchema } from './schema';
import Field from '../Form/Field';
import FormCol from '../Form/FormCol';
import CommonFields from './StepOneTwoCommonFields';
import { scrollTop, getProvinces } from '../../utils';

const StepTwo = ({ onSubmit, stepData }) => {
  const [t, i18next] = useTranslation();
  const defaultLang = t(i18next.language && i18next.language.toUpperCase());
  const stepDataObject = stepData.adminData || stepData;
  const {
    firstName,
    lastName,
    email,
    mobileNumber,
    province,
    preferredLanguage,
  } = stepDataObject;

  const [adminData, setAdminData] = useState(
    stepData.adminData || {
      thatsMe: false,
      firstName: '',
      lastName: '',
      email: '',
      mobileNumber: '',
      province: '',
      preferredLanguage: defaultLang,
    }
  );

  useEffect(() => {
    scrollTop();
  }, []);

  useEffect(() => {
    setAdminData((state) => {
      return {
        ...state,
        preferredLanguage: t(
          i18next.language && i18next.language.toUpperCase()
        ),
      };
    });
  }, [i18next.language, t]);

  useEffect(() => {
    if (stepData.adminData) {
      setAdminData(stepData.adminData);
    }
  }, [stepData.adminData]);

  const handleFormSubmit = (values) => {
    onSubmit({ adminData: values });
  };

  return (
    <Formik
      validationSchema={getStepTwoSchema(t)}
      onSubmit={handleFormSubmit}
      enableReinitialize
      initialValues={{
        ...adminData,
        thatsMe: (adminData && adminData.thatsMe) || false,
      }}
    >
      {({ handleSubmit, errors, touched }) => (
        <Form onSubmit={handleSubmit}>
          <FlexGrid gutter={false}>
            <div style={{marginLeft: 15, marginRight: 15}}>
              <Text>{t('STEP_2_DESCRIPTION')}</Text>
            </div>
            <FlexGrid.Row>
              <Field
                name="thatsMe"
                label={t('THATS_ME')}
                type="checkbox"
                onChange={(evt) => {
                  const { checked } = evt.target;
                  if (checked) {
                    setAdminData({
                      thatsMe: checked,
                      firstName,
                      lastName,
                      email,
                      mobileNumber,
                      province,
                      preferredLanguage: preferredLanguage || defaultLang,
                    });
                  } else {
                    setAdminData({
                      thatsMe: checked,
                      firstName: '',
                      lastName: '',
                      email: '',
                      mobileNumber: '',
                      province: '',
                      preferredLanguage: defaultLang,
                    });
                  }
                }}
                touched={touched}
                errors={errors}
              />
              <CommonFields touched={touched} errors={errors} showTooltip={false} />
              <Field
                name="province"
                type="select"
                label={t('PROVINCE')}
                touched={touched}
                errors={errors}
                options={getProvinces(t)}
                placeholder={t('SELECT_PROVINCE')}
              />
              <Field
                name="preferredLanguage"
                type="radio"
                label={t('PREFERRED_LANGUAGE')}
                options={[t('EN'), t('FR')]}
                touched={touched}
                errors={errors}
              />
            </FlexGrid.Row>
            <FlexGrid.Row horizontalAlign="center">
              <FormCol md={6} xs={12}>
                <Button type="submit">{t('NEXT')}</Button>
              </FormCol>
            </FlexGrid.Row>
          </FlexGrid>
        </Form>
      )}
    </Formik>
  );
};

StepTwo.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  stepData: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default StepTwo;
