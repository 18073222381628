import React, { useEffect } from 'react';
import Button from '@tds/core-button';
import Text from '@tds/core-text'
import Paragraph from '@tds/core-paragraph'
import FlexGrid from '@tds/core-flex-grid';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { getStepOneSchema } from './schema';
import Field from '../Form/Field';
import FormCol from '../Form/FormCol';
import CommonFields from './StepOneTwoCommonFields';
import { scrollTop } from '../../utils';

const StepOne = ({
  onSubmit,
  stepData: { legalBusinessName, firstName, lastName, email, mobileNumber },
}) => {
  const [t] = useTranslation();

  useEffect(() => {
    scrollTop();
  }, []);

  return (
    <Formik
      validationSchema={getStepOneSchema(t)}
      onSubmit={onSubmit}
      initialValues={{
        legalBusinessName: legalBusinessName || '',
        firstName: firstName || '',
        lastName: lastName || '',
        email: email || '',
        mobileNumber: mobileNumber || '',
      }}
    >
      {({ handleSubmit, errors, touched }) => (
        <Form onSubmit={handleSubmit}>
          <FlexGrid gutter={false}>
            <FlexGrid.Row>
              <Field
                name="legalBusinessName"
                label={t('LEGAL_BUSINESS_NAME')}
                touched={touched}
                errors={errors}
              />
              <CommonFields touched={touched} errors={errors} showMobilePhoneNote/>
            </FlexGrid.Row>
            <FlexGrid.Row>
            <FormCol md={12} xs={12}>
              <Paragraph size='small' align="left">
              <Text bold ml={6}>{t('COMPANY_INFO_NOTE_LABEL')}:</Text> {t('COMPANY_INFO_NOTE')}</Paragraph>
              </FormCol>
            </FlexGrid.Row>
            <FlexGrid.Row horizontalAlign="center">
              <FormCol md={6} xs={12}>
                <Button type="submit">{t('NEXT')}</Button>
              </FormCol>
            </FlexGrid.Row>
          </FlexGrid>
        </Form>
      )}
    </Formik>
  );
};

StepOne.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  stepData: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default StepOne;
