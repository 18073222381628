import React, { useEffect, useState } from 'react';
import Button from '@tds/core-button';
import FlexGrid from '@tds/core-flex-grid';
import Notification from '@tds/core-notification';
import Box from '@tds/core-box';
import HairlineDivider from '@tds/core-hairline-divider'
import Text from '@tds/core-text'
import Heading from '@tds/core-heading';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { getStepThreeSchema } from './schema';
import Field from '../Form/Field';
import FormCol from '../Form/FormCol';
import { scrollTop } from '../../utils';
import { withRouter, useLocation } from 'react-router-dom';
import { NormalText } from '../../styles/elements';


const StepThree = ({
  onSubmit,
  stepData: { additionalEmployee, noOfEmployees, totalPerMonth },
}) => {
  let initialState = noOfEmployees ? true : null;
  const [show, setShow] = useState(initialState);
  const [t] = useTranslation();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const noOfEmployess = query.get('no');
  const formikKeys = {
    TOTAL_PER_MONTH: "totalPerMonth",
    ADDITIONAL_EMPLOYEE: "additionalEmployee",
    NO_OF_EMPLOYEES: "noOfEmployees"
  }
  useEffect(() => {
    scrollTop();
  }, []);

  const handleChange = (event, setFieldValue) => {
    const { target: { value, name } } = event;
    setFieldValue(formikKeys.TOTAL_PER_MONTH, "")
    if (name === formikKeys.ADDITIONAL_EMPLOYEE && value === t('YES')) {
      setShow(true);
    } else if (name === formikKeys.ADDITIONAL_EMPLOYEE && value === t('NO')) {
      setShow(false);
      setFieldValue(formikKeys.NO_OF_EMPLOYEES, '');
      setFieldValue(formikKeys.TOTAL_PER_MONTH, '');
    }
    if (name === formikKeys.NO_OF_EMPLOYEES && value <= 30) {
      setFieldValue(formikKeys.TOTAL_PER_MONTH, (value * 5).toFixed(2))
    }
  };
  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{
        additionalEmployee: additionalEmployee || '',
        noOfEmployees: noOfEmployees || '',
        totalPerMonth: totalPerMonth || ''
      }}
      validate={values => {
        const errors = {};
        if (!values.additionalEmployee) {
          errors.additionalEmployee = t('ADDITIONAL_EMPLOYEE_REQUIRED');
        }
        if(values.additionalEmployee === t('YES')){
          if (!values.noOfEmployees) {
            errors.noOfEmployees = t('NO_OF_EMPLOYEES_REQUIRED');
          } else if(Number(values.noOfEmployees) + Number(noOfEmployess) > 29){
            errors.noOfEmployees = t('NOT_MORE_THAN_30');
          }
        }
        return errors;
      }}
    >
      {({ handleSubmit, errors, touched, setFieldValue }) => (
        <Form onSubmit={handleSubmit} onChange={(e) => handleChange(e, setFieldValue)}>
          <FlexGrid gutter={false} mb={3}>
            <div style={{ marginLeft: 15, marginBottom: 5 }}>
              <Heading level='h3'>{t('ORDER_DETAILS')}</Heading>
            </div>

            <div style={{ marginBottom: 10, marginLeft: 15 }}>
              <Box between={1}>
                <Heading level="h4">
                  {t('OFFER')} :{' '}
                  <NormalText>{t('PRODUCT_OFFER')}</NormalText>
                </Heading>
                <Heading level="h4">
                  {t('NO_OF_ELIGIBLE_EMPLOYEE')} :{' '}
                  <NormalText>{noOfEmployess || 0}</NormalText>
                </Heading>
                <Text bold size='small'>
                  {t('NOTE')}: <Text size='small' >{t('PRODUCT_INFO_NOTE')}</Text>
                </Text>
              </Box>
            </div>
            <div>
              <Field
                name="additionalEmployee"
                type="radio"
                label={t('ADDITIONAL_EMPLOYEE')}
                options={[t('YES'), t('NO')]}
                touched={touched}
                errors={errors}
                isNewStep={true}
                note={`${t('NOTE')}:`}
                desciptiveText={t('PRODUCT_INFO_WARNING')}
              />
            </div>


            {show &&
              <>
                <Field
                  name="noOfEmployees"
                  label={t('PRODUCT_INFO_GREAT')}
                  touched={touched}
                  errors={errors}
                  type='number'
                />
                <Field
                  name="totalPerMonth"
                  id="totalPerMonth"
                  label={t('PRODUCT_INFO_TOTAL_PER_MONTH')}
                  touched={touched}
                  errors={errors}
                  disabled={true}
                  note={`${t('NOTE')}:`}
                  desciptiveText={t('PRODUCT_ADDITIONAL_CHARGES')}
                />
              </>
            }
            <FlexGrid.Row>
              {show === false &&
                <>
                  <Box between={4}>

                    <HairlineDivider gradient />
                    <Notification copy="en">
                      <Text bold>{t('NOTE')}:</Text> {t('PRODUCT_INFO_NO_WARNING')}
                    </Notification>
                  </Box>
                </>
              }

            </FlexGrid.Row>
            <FlexGrid.Row horizontalAlign="center">
              <FormCol md={6} xs={12}>
                <Button type="submit">{t('NEXT')}</Button>
              </FormCol>
            </FlexGrid.Row>
          </FlexGrid>
        </Form>
      )
      }
    </Formik >
  );
};

StepThree.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  stepData: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withRouter(StepThree);
