export const scrollTop = () => {
    return window.scrollTo(0, 0);
};

export const getProvinces = (t) => {
    const provinces = [
        "ALBERTA",
        "BRITISH_COLUMBIA",
        "ONTARIO",
    ].map(op => t(op)).map((op) => ({ text: op, value: op }));

    return provinces;
};

export default {}; 