import React, { useState, useEffect } from 'react';
import Button from '@tds/core-button';
import { CompleteButton } from '../../styles/elements';
import FlexGrid from '@tds/core-flex-grid';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Box from '@tds/core-box';
import Heading from '@tds/core-heading';
import Checkbox from '@tds/core-checkbox';
import Spinner from '@tds/core-spinner';
import Notification from '@tds/core-notification';
import Text from '@tds/core-text';
import axios from 'axios';
import { withRouter, useLocation } from 'react-router-dom';
import { history as historyPropTypes } from 'history-prop-types';

import config from '../../config';
import { feedbackTypes } from '../../config/constants';
import FormCol from '../Form/FormCol';
import TermsAndConditions from '../Terms';
import Summary from '../Summary';
import { Break } from '../../styles/elements';
import { scrollTop } from '../../utils';

const StepFour = ({ setStep, stepData, history }) => {
  const [t, i18next] = useTranslation();
  const [orderDetailsCorrect, setOrderDetailsCorrect] = useState(false);
  const { API_BASE } = config;
  const { ERROR } = feedbackTypes;
  const [accept, setAccept] = useState(false);
  const [touched, setTouched] = useState(false);
  const [spinning, setSpinning] = useState(false);
  const [feedback, setFeedback] = useState(null);
  const [isScrolled, setIsScrolled] = useState(false)
  const [error, setError] = useState(undefined);
  const [feedbackType, setFeedbackType] = useState(undefined);
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const noOfEmployess = query.get('no');

  useEffect(() => {
    scrollTop();
  }, []);


  const completeSteps = () => {
    setTouched(true);
    setError(undefined);
    setFeedbackType(undefined);
    if (accept) {
      const completeData = { ...stepData };
      const { thatsMe } = completeData.adminData;
      delete completeData.adminData.thatsMe;
      const finalData = {
        ...completeData,
        isSelfUser: thatsMe,
        employeeQueryParam: noOfEmployess || 0,
        lang: i18next.language.toUpperCase(),
      };
      setSpinning(true);
      axios
        .post(`${API_BASE}customers`, finalData)
        .then(({ data }) => {
          const { supportEmailError, customerEmailError } = data || {};
          setSpinning(false);
          if (customerEmailError) {
            setFeedback({
              type: ERROR,
              message: t('FEEDBACK_ERROR_CUSTOMER'),
            });
          } else if (supportEmailError) {
            setFeedback({
              type: ERROR,
              message: t('FEEDBACK_ERROR'),
            });
          } else {
            history.push(`/${i18next.language}/success`);
          }
        })
        .catch(() => {
          setSpinning(false);
          setFeedback({
            type: ERROR,
            message: t('FEEDBACK_ERROR'),
          });
        });
    }
    else{
      setError('MUST_ACCEPT');
      setFeedbackType('error');
    }
  };
  const onAcceptTerms = (e) => {
    if(!isScrolled){
      setError('MUST_SCROLL');
      setFeedbackType('error')
    }
    else{
      setError(undefined);
      setFeedbackType(undefined)
      setAccept(e.target.checked);
    }
  }
  return (
    <>
      <Spinner fullScreen label={t('SUBMITTING_INFO')} spinning={spinning} />
      {!orderDetailsCorrect && (
        <>
          <Summary stepData={stepData} noOfEmployess={noOfEmployess} />
          <FlexGrid>
            <FlexGrid.Row horizontalAlign="center">
              <FormCol md={6} xs={12}>
                <Button
                  variant="secondary"
                  type="submit"
                  onClick={() => setStep(0)}
                >
                  {t('I_WANT_TO_MAKE_CHANGES')}
                </Button>
              </FormCol>
              <FormCol md={6} xs={12}>
                <Button
                  onClick={() => {
                    setOrderDetailsCorrect(true);
                    scrollTop();
                  }}
                  type="submit"
                >
                  {t('ORDER_DETAILS_CORRENT')}
                </Button>
              </FormCol>
            </FlexGrid.Row>
          </FlexGrid>
        </>
      )}
      {orderDetailsCorrect && (
        <div>
          <Box between={3} horizontal={3}>
            <Heading level="h2">{t('CONTRACT_TERMS')}</Heading>
            <Text bold size='small'>
                  {t('NOTE')}: <Text size='small' >{t('MUST_SCROLL')}</Text>
            </Text>
            <TermsAndConditions setIsScrolled={setIsScrolled}/>
            <FlexGrid gutter={false}>
              <FlexGrid.Row>
                <FlexGrid.Col md={6} xs={12}>
                  <Checkbox
                    name="agreement"
                    value="agreement"
                    checked={accept}
                    error={
                      t(error)
                    }
                    feedback={feedbackType}
                    onChange={(e) => {
                      onAcceptTerms(e)
                    }}
                    label={t('I_ACCEPT')}
                  />
                </FlexGrid.Col>
              </FlexGrid.Row>
            </FlexGrid>
          </Box>
          <FlexGrid>
            <FlexGrid.Row horizontalAlign="center">
              <>
                {feedback && (
                  <Notification variant={feedback.type} copy="en">
                    <Text>{feedback.message}</Text>
                  </Notification>
                )}
              </>
            </FlexGrid.Row>
            <FlexGrid.Row horizontalAlign="center">
              <FormCol md={6} xs={12}>
                <CompleteButton onClick={completeSteps} disabled={!accept}>
                  {t('COMPLETE')}
                </CompleteButton>
              </FormCol>
            </FlexGrid.Row>
          </FlexGrid>
        </div>
      )}
    </>
  );
};

StepFour.propTypes = {
  setStep: PropTypes.func.isRequired,
  stepData: PropTypes.objectOf(PropTypes.any).isRequired,
  history: PropTypes.shape(historyPropTypes).isRequired,
};

export default withRouter(StepFour);
